@import '~ethos-design-system/src/components/Media/Media.scss';

.root {
  padding-top: var(--Space-32);
  padding-bottom: var(--Space-32);

  @include for-tablet-only {
    padding-bottom: var(--Space-64);
  }
  @include for-laptop-and-up {
    padding-top: var(--Space-40);
    padding-bottom: 120px;
  }
}

.Breadcrumbs {
  display: flex;
  align-items: center;
  padding-bottom: var(--Space-48);
  flex-wrap: wrap;

  @include for-laptop-and-up {
    padding-bottom: var(--Space-56);
  }

  * {
    color: var(--GraySecondary--opaque);
  }

  span {
    padding: 0 var(--Space-8);
    font-size: 10px;
  }

  .LaptopAndUp,
  .PhoneAndTablet {
    display: flex;
    align-items: center;
  }

  .PhoneAndTablet {
    @include for-laptop-and-up {
      display: none;
    }
  }

  .LaptopAndUp {
    @include for-phone-and-tablet {
      display: none;
    }
  }
}

.Question {
  padding-bottom: var(--Space-16);

  @include for-laptop-and-up {
    padding-bottom: var(--Space-32);
  }
}

.Content {
  display: flex;
  justify-content: space-between;

  @include for-phone-and-tablet {
    flex-wrap: wrap;
  }

  @include for-laptop-and-up {
    justify-content: flex-start;
  }
}

.Answer {
  @include for-laptop-only {
    max-width: 440px;
  }

  @include for-desktop-only {
    margin-right: var(--Space-40);
    max-width: 560px;
  }

  > div > *:first-child {
    margin-top: 0;
  }

  h2 {
    font-weight: 500;
  }
}

.Related {
  @include for-phone-and-tablet {
    width: 100%;
    margin-top: var(--Space-64);
  }

  @include for-laptop-and-up {
    min-width: 240px;
    max-width: 240px;
    margin-left: 120px;
  }
}

.RelatedQuestions {
  padding-top: var(--Space-32);

  svg {
    fill: var(--GrayPrimary--opaque);
    margin-left: var(--Space-4);
    width: 10px;
    height: 10px;
  }

  .RelatedQuestion {
    > a > div {
      display: inline-block;
      max-width: calc(
        100% - 20px
      ); // to prevent the arrow icon from landing alone on a newline
    }

    &:not(:last-child) {
      padding-bottom: var(--Space-16);
      margin-bottom: var(--Space-16);
      border-bottom: solid 1px var(--GrayStrokeAndDisabled--opaque);
    }

    &:hover span {
      text-decoration: underline;
    }

    .RelatedAnswer {
      @include for-tablet-and-up {
        display: none;
      }
    }
  }
}
