@import '~ethos-design-system/src/components/Media/Media.scss';

.Breadcrumbs {
  display: flex;
  align-items: center;

  * {
    color: var(--GraySecondary--opaque);
  }

  span {
    padding: 0 var(--Space-8);
    font-size: 10px;
  }
}
