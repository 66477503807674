@import '~ethos-design-system/src/components/Media/Media.scss';
.moduleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  svg {
    max-width: 16px;
    :hover {
      opacity: 0.6;
    }
  }
  button {
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    &:hover {
      opacity: 0.6;
    }
  }
}

.wrapper {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 200px;
  right: 0;
  box-shadow: rgba(68, 74, 87, 0.05) 0px 2px 6px 0px,
    rgba(68, 74, 87, 0.5) 0px 1px 3px 0px;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  padding: 16px;
  z-index: 9999;
  overflow-y: auto;
  @include for-tablet-and-up {
    height: calc(100vh - 300px);
    top: 70px;
    width: 260px;
  }
}
