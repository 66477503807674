@import '~ethos-design-system/src/components/Media/Media.scss';

$text-max-width: 560px;
$default-bg-color: #f5f3f0; // custom for this module "Web Sand"

@keyframes fade {
  0%,
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.root {
  display: flex;
}

.default {
  background: $default-bg-color;
}

.moss {
  background: var(--BrandMoss);
}

.white {
  background: var(--White);
}

.duckegg {
  background: var(--BrandDuckegg);
}

.buttercup {
  background: var(--BrandButtercup);
}

.full {
  min-height: 50vh;

  @include for-phone-only {
    padding-top: var(--Space-24);
  }

  @include for-tablet-and-up {
    &.alignTop {
      padding-top: var(--Space-80);
      padding-bottom: var(--Space-56);
    }
  }

  @include for-laptop-and-up {
    min-height: 70vh;

    &.alignCenter {
      align-items: center;

      .text {
        padding-top: var(--Space-56);
      }
    }

    &.alignTop {
      align-items: flex-start;
    }
  }

  .results li {
    @include for-laptop-only {
      width: calc(50% - var(--Space-16));
    }

    @include for-desktop-only {
      width: calc(50% - var(--Space-40));
    }
  }

  @include for-desktop-only {
    min-height: calc(100vh - var(--Navbar-Height));
  }

  .helpText {
    animation: fade 0.5s ease-in-out;
  }
  .results {
    animation: fade 1s ease-in-out;
  }
}

.preview {
  @include for-phone-and-tablet {
    padding-top: var(--Space-32);
    padding-bottom: var(--Space-8);

    .searchImage {
      display: none;
    }
  }

  @include for-laptop-and-up {
    .searchInner {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-top: var(--Space-80);
      padding-bottom: var(--Space-32);
    }

    .searchResults {
      width: 100%;
    }
  }

  @include for-laptop-only {
    .searchContent,
    .searchImage {
      min-width: calc(50% - var(--Space-40));
      max-width: calc(50% - var(--Space-40));
    }
  }

  @include for-desktop-only {
    .searchContent,
    .searchImage {
      max-width: calc(50% - var(--Space-40));
    }
  }

  .results {
    max-width: $text-max-width;

    li {
      width: 100%;
    }
  }
}

.search {
  @include for-phone-only {
    padding-top: var(--Space-32);
    padding-bottom: var(--Space-40);
  }

  @include for-tablet-only {
    padding-bottom: var(--Space-64);
  }

  @include for-tablet-and-up {
    padding-top: var(--Space-64);
  }

  @include for-laptop-only {
    padding-bottom: var(--Space-56);
  }

  @include for-desktop-only {
    padding-bottom: var(--Space-64);
  }

  .searchInner {
    display: flex;
    justify-content: space-between;

    .searchBar {
      height: 45px;

      @include for-laptop-and-up {
        align-self: flex-end;
        margin-bottom: 3px;
      }
    }

    @include for-phone-and-tablet {
      flex-direction: column;

      .searchBar {
        max-width: none;
      }
    }

    @include for-laptop-and-up {
      .searchBar,
      .text {
        width: calc(50% - var(--Space-32));
      }
    }
  }

  .searchContent {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @include for-phone-and-tablet {
      flex-direction: column;
    }
  }
}

.text {
  max-width: $text-max-width;
}

.results {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;

  li {
    opacity: 1;
    list-style: none;

    a {
      text-decoration: none;
    }
  }

  .caption {
    color: var(--GrayPrimary--opaque);
  }
}

.searchBar {
  display: flex;
  justify-content: space-between;
  max-width: $text-max-width;
  border-bottom: solid 1px var(--GrayPrimary--opaque);
  position: relative;

  input {
    width: calc(100% - 25px);
    border: none;
    background: transparent;
    font-weight: 300;
    font-size: 23.5px;
    line-height: 28px;
    color: var(--GraySecondary--opaque);
    padding: var(--Space-8) var(--Space-8) var(--Space-8) 0;

    &:focus {
      outline: none;
    }

    &:focus + .outline {
      display: block;
    }
  }

  .outline {
    content: '';
    width: 100%;
    height: 1px;
    box-shadow: 0px 3px 3px 1px #5e9ed6;
    position: absolute;
    left: 0;
    bottom: 0;
    display: none;
  }
}

.searchIcon {
  -webkit-appearance: none;
  border: 0;
  background: transparent;
  padding: 0;
  cursor: pointer;
}

.helpText {
  opacity: 1;
}

// prevent helpText changing vertical align center layout for full variation on first load
// without this the height of the SearchIndex component will increase if the helpText is
// activated before the layout is set to align top
.helpTextSpacer {
  height: 43px;
}
